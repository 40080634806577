import i18n from '../../i18n/lang'

const getCountryFlag = (code) => {
  return `<img class="country-flag" src="${require(`@/assets/img/flags/${code}.png`)}" alt="${code}" />`
}

const state = {
  messages: [],
  socket: null,
  wsStatus: 'finished',
  wsContinue: false,
  isLimitExceeded: false,
  welcomeMessage: {
    question: '',
    answer: '',
    type: 'welcome'
  }
}
const mutations = {
  updateMessages (state, messages) {
    state.messages = messages
  },
  setMessages (state, messages) {
    state.messages.push(messages)
  },
  resetMessages (state) {
    state.messages = []
  },
  setWsStatus (state, status) {
    state.wsStatus = status
  },
  setWsContinue (state, status) {
    state.wsContinue = status
  },
  setWelcomeMessage (state) {
    state.welcomeMessage.answer = state
  }
}
const getters = {
  getMessages (state) {
    return state.messages
  },
  getWsStatus (state, status) {
    return state.wsStatus
  },
  getWsContinue (state) {
    return state.wsContinue
  },
  prepareWelcomeMessage (state, getters, rootState) {
    const message = JSON.parse(JSON.stringify(state.welcomeMessage))
    const languages = process.env.VUE_APP_LANGS.split(',')
    const userNAme = rootState.auth.userName || localStorage.getItem('aica_username')
    message.answer = languages.map(language => `${i18n.t('chat.welcome', language, { name: userNAme ? ` ${userNAme}` : '' })} ${getCountryFlag(language)}`).join('<br>')

    return message
  }
}
const actions = {
  sendMessage ({ commit, state, rootState }, question) {
    return new Promise((resolve, reject) => {
      const uuid = Math.random().toString(16).slice(2)
      const token = localStorage.getItem('accessToken')
      const locationId = localStorage.getItem('locationId')
      const forcedLanguage = rootState.translations.chatLang

      commit('setMessages', {
        question,
        answer: '',
        id: uuid
      })

      commit('setWsStatus', 'starting')
      const payload = {
        question: question
      }

      if (forcedLanguage) {
        payload.forced_language = forcedLanguage.toUpperCase()
      }

      if (this.socket === undefined) {
        this.socket = new WebSocket(state.wsContinue ? `${process.env.VUE_APP_CHAT_WEBSOCKET}/api/v1/qa/ws-chat/continue` : `${process.env.VUE_APP_CHAT_WEBSOCKET}/api/v1/qa/ws-chat/?loc_id=${locationId || 0}`)

        this.socket.onopen = () => {
          this.socket.send(JSON.stringify({ token }))
          if (question === null) {
            return
          }
          this.socket.send(JSON.stringify(payload))
        }
      } else {
        this.socket.send(JSON.stringify(payload))
      }

      // this.socket.onclose = function (e) {
      //   console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
      //   setTimeout(function () {
      //     // connect()
      //     console.log('asd')
      //   }, 1000)
      // }

      this.socket.onmessage = (e) => {
        const response = JSON.parse(e.data)
        const messages = JSON.parse(JSON.stringify(state.messages))
        const findResponse = messages.findIndex(response => response.id === uuid)
        messages[findResponse].answer += response.piece ? response.piece : ''
        messages[findResponse].state = response.state

        if (response.link) {
          messages[findResponse].link = response.link
        }

        commit('setWsStatus', response.state)
        commit('updateMessages', messages)
      }
    })
  },
  welcomeMessage ({ commit, state }) {
    if (state.messages.length) {
      return
    }
    setTimeout(() => {
      commit('updateMessages', state.welcomeMessage)
    }, 600)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
