<template>
  <div class="chatFooter">
    <div class="inner">
      <input type="text" :placeholder="$t('chat.type_message')" name="chatMessage" v-model="message" ref="chatMessage"
             @keyup.enter="sendMessage" :disabled="wsStatus === 'limit_exceeded'" />
      <button type="button" @click="sendMessage" :disabled="!allowSend">{{ $t('chat.send') }}</button>
    </div>
    <div class="info">{{ $t('chat.disclaimer') }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  data () {
    return {
      message: null,
      allowedStatuses: ['finished', 'validation_failed', 'language_not_allowed']
    }
  },
  computed: {
    ...mapGetters({
      wsStatus: 'messages/getWsStatus'
    }),
    allowSend () {
      return this.allowedStatuses.includes(this.wsStatus) && this.wsStatus !== 'limit_exceeded' && this.message
    }
  },
  watch: {
    wsStatus (n) {
      if (this.allowedStatuses.includes(n)) {
        this.$refs.chatMessage.focus()
      }
    }
  },
  mounted () {
    this.$refs.chatMessage.focus()
  },
  methods: {
    sendMessage () {
      if (!this.allowSend) {
        return
      }
      this.$emit('send', this.message)
      this.message = ''
    }
  }
}
</script>

<style scoped lang="scss">
.chatFooter {
  background: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-top: 1px solid #F1F1F1;
  .inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
    input[type=text] {
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #555;
      border: none;
      flex: 1;
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      &:focus, &:active, &:disabled {
        border: 0;
        outline: 0;
        background-color: #fff !important;
      }
      &:-webkit-autofill:focus, &:-webkit-autofill {
        -webkit-box-shadow:0 0 0 50px white inset;
        -webkit-text-fill-color: #333;
      }
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 7px 20px;
      gap: 10px;
      color: #fff;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      background-color: var(--main-color);
      border-radius: 15px;
      border: none;
      font-weight: 500;
      cursor: pointer;
      margin: 0;
      font-family: 'Roboto', sans-serif;
      -webkit-transition: all 1s ease-out;
      -moz-transition: all 1s ease-out;
      -o-transition: all 1s ease-out;
      transition: all 1s ease-out;
      &:not(:disabled):hover {
        background-color: var(--secondary-color);
      }
      &:disabled {
        opacity: .7;
        cursor: default;
      }
    }
  }
  .info {
    flex: 0 0 100%;
    background: #f9f9f9;
    font-size: 9px;
    padding: 6px 15px;
    color: #999;
    text-align: center;
    border-top: 1px solid #F1F1F1;
  }
}
</style>
