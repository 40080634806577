<template>
  <div class="language-switcher" :class="{ compact: compact }" @keydown.esc.exact="hideDropdown" @keydown.tab.exact="hideDropdown">
    <span class="prefix" v-if="prefix">{{ $t('auth.lang') }}</span>
    <div class="language-container">
    <button type="button" class="language-button" :class="{ collapsed: isVisible }" @click="toggleVisibility">
      <template v-if="!global && getInterfaceLang">
        <img :src="require(`@/assets/img/flags/${getInterfaceLang}.png`)" :alt="getInterfaceLang">
      </template>
      <template v-if="global && getChatLang">
        <img :src="require(`@/assets/img/flags/${getInterfaceLang}.png`)" :alt="getInterfaceLang">
      </template>
      <span v-if="global && !getChatLang">{{ $t('chat.chat_language') }}</span>
      <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path class="heroicon-ui"
              d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"></path>
      </svg>
    </button>
    <transition name="dropdown-fade">
      <ul v-if="isVisible" ref="dropdown" class="list">
        <li :class="{ selected: getSelectedLanguage === language }" v-for="language in languages" :key="language">
          <a href="#" @click.prevent="setInterfaceLocale(language)" class="link" @keydown.shift.tab="focusPrevious(false)"
             @keydown.up.exact.prevent="focusPrevious(true)">
            <img :src="require(`@/assets/img/flags/${language}.png`)" :alt="language">
          </a>
        </li>
      </ul>
    </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LanguageSwitch',
  props: {
    prefix: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    global: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isVisible: false,
      focusedIndex: 0,
      languages: process.env.VUE_APP_LANGS.split(',')
    }
  },
  computed: {
    ...mapGetters({
      getInterfaceLang: 'translations/getInterfaceLang',
      getChatLang: 'translations/getChatLang'
    }),
    getSelectedLanguage () {
      if (this.global) {
        return this.getChatLang
      }

      return this.getInterfaceLang
    }
  },
  methods: {
    ...mapMutations({
      setInterfaceLang: 'translations/setInterfaceLang',
      setChatLang: 'translations/setChatLang'
    }),
    toggleVisibility () {
      this.isVisible = !this.isVisible
    },
    hideDropdown () {
      this.isVisible = false
      this.focusedIndex = 0
    },
    focusPrevious (isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusItem () {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus()
    },
    setInterfaceLocale (locale) {
      this.$root.$i18n.locale = locale
      localStorage.setItem('aica_i_lang', locale)
      this.setInterfaceLang(locale)

      if (this.global) {
        this.setChatLocale(locale)
      }

      this.hideDropdown()
    },
    setChatLocale (locale) {
      this.setChatLang(locale)
      localStorage.setItem('aica_c_lang', locale)
      this.hideDropdown()
    }
  }
}
</script>

<style scoped lang="scss">
.language-switcher {
  position: relative;
  width: auto;
  margin-right: auto;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  .prefix {
    color: #999;
    font-size: 13px;
  }
  .language-container {
    position: relative;
  }
  .language-button {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #F1F1F1;
    padding: 5px 5px 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    max-width: 125px;
    transition: background-color 0.3s, border 0.3s;
    @media (max-width: 480px) {
      max-width: none;
    }
    &:hover {
      border: 1px solid #ccc;
    }
    span {
      white-space: nowrap;
      font-size: 11px;
      color: #999;
    }
    svg {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      display: inline-block;
      transition: all .2s;
      path {
        fill: #999;
      }
    }
    &.collapsed {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  img {
    max-width: 24px;
    height: auto;
  }
  .list {
    position: absolute;
    list-style-type: none;
    overflow: hidden;
    z-index: 9999;
    border-radius: 0.25rem;
    margin: 3px 0 0 0;
    padding: 0;
    left: 0;
    right: 0;
    font-weight: 400;
    text-transform: none;
    background-color: #ffffff;
    border: 1px solid #F1F1F1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    li {
      .link {
        display: flex;
        padding: 0.25rem 0.75rem;
        align-items: center;
        text-decoration: none;
        justify-content: center;
        color:black;
      }
      &:hover {
        background-color: #E5E7EB;
      }
      &.selected {
        background-color: #f1f1f1;
      }
    }

    @media (min-width: 1024px) {
      left: 0;
      right: 0;
      z-index: 20;
    }
  }

  &.compact {
    .language-button {
      padding: 2px 6px;
      width: 60px;
    }
  }
}
</style>
