import http from '../../services/http'

const state = {
  isOpen: false,
  loading: false,
  authState: null,
  locationID: null
}
const mutations = {
  setIsOpen (state, isOpen) {
    state.isOpen = isOpen
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAuthState (state, authState) {
    state.authState = authState
  },
  setLocationId (state, locationId) {
    state.locationID = locationId
  }
}
const getters = {
  getIsOpen (state) {
    return state.isOpen
  },
  getLoading (state) {
    return state.loading
  },
  getAuthState (state) {
    return state.authState
  }
}
const actions = {
  fetchMe ({ commit, dispatch }) {
    if (!localStorage.getItem('accessToken')) {
      dispatch('closeChat')
      return
    }
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      http
        .get('/user/me/').then((r) => {
          dispatch('checkLatestSession')
          commit('setLoading', false)
          commit('setIsOpen', true)
          commit('setAuthState', 'loggedIn')
          resolve(r.data)
        })
        .catch((e) => {
          commit('setLoading', false)
          dispatch('closeChat')
          reject(e)
        })
    })
  },
  setLocationId ({ commit }, locationId) {
    if (locationId) {
      localStorage.setItem('locationId', locationId)
    }
  },
  closeChat ({ commit }) {
    commit('messages/resetMessages', null, { root: true })
    commit('messages/setWsStatus', 'finished', { root: true })
    commit('auth/resetAuth', null, { root: true })
    commit('translations/resetTranslations', null, { root: true })
    commit('setAuthState', 'loggedOut')
  },
  checkLatestSession ({ commit, dispatch, rootState, rootGetters }) {
    const welcomeMessage = rootGetters['messages/prepareWelcomeMessage']
    http.get(`${process.env.VUE_APP_CHAT_CORE}/conversations/latest`).then((r) => {
      const conversations = r.data.interactions.map(interaction => {
        return {
          ...interaction,
          state: 'finished'
        }
      })

      conversations.unshift(welcomeMessage)
      commit('messages/setWsContinue', true, { root: true })
      commit('messages/updateMessages', conversations, { root: true })
    }).catch(() => {
      commit('messages/setWsContinue', false, { root: true })
      commit('messages/updateMessages', [welcomeMessage], { root: true })
    }).finally(() => {
      commit('setLoading', false)
      dispatch('calculateBodyHeight')
    })
  },
  calculateBodyHeight ({ dispatch }) {
    const header = document.querySelector('.chatHeader')
    const footer = document.querySelector('.chatFooter')
    const body = document.querySelector('.chatBody')

    const offset = (header.clientHeight + footer.clientHeight) + 12
    body.style.maxHeight = `${window.innerHeight - offset}px`
    body.style.bottom = `${footer.clientHeight}px`
    body.style.top = `${header.clientHeight}px`

    setTimeout(() => {
      dispatch('calculateInsideHeight').then(() => dispatch('scrollToEnd'))
    }, 100)
  },
  calculateInsideHeight () {
    return new Promise((resolve) => {
      setTimeout(() => {
        const body = document.querySelector('.chatBody')
        const buyGet = document.querySelector('.buy-get')
        const inside = document.querySelector('.chatInside')
        inside.style.maxHeight = `${body.clientHeight - (buyGet ? buyGet.clientHeight : 0) - 20}px`
        resolve()
      }, 100)
    })
  },
  scrollToEnd () {
    const chatBody = document.querySelector('.chatInside')
    chatBody.scrollTop = chatBody.scrollHeight
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
