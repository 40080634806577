<template>
  <div class="chatResponse">
    <transition name="fade-in">
      <div class="messageTip messageTip__him" v-if="question">
        <div class="message">{{ question | urlify }}</div>
      </div>
    </transition>
    <transition name="fade-in">
      <div class="messageTip messageTip__own" :class="{ welcome: type === 'welcome' }" v-if="answer">
        <div class="message" v-html="prepareAnswer"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import urlify from '../../filters/urlify'
export default {
  name: 'MessageTip',
  props: {
    question: {
      required: true,
      type: String
    },
    answer: {
      type: String
    },
    index: {
      type: Number
    },
    type: {
      type: String
    },
    state: {
      type: String
    },
    link: {
      type: String
    }
  },
  filters: {
    urlify
  },
  watch: {
    state (n) {
      if (n === 'finished') {
        setTimeout(() => this.$emit('finished'), 100)
      }
    }
  },
  computed: {
    prepareAnswer () {
      const answer = this.$options.filters.urlify(this.answer)
      const images = ['jpg', 'jpeg', 'png', 'webp']

      if (!this.link) {
        return answer
      }

      if (images.some(v => this.link.includes(v)) && this.state === 'finished') {
        return answer + this.prepareImage(this.link)
      }

      const movieID = this.getYTMovieID(this.link)

      if (movieID && this.state === 'finished') {
        return answer + this.prepareYTEmbed(movieID)
      }

      return answer
    }
  },
  methods: {
    getYTMovieID (url) {
      const regExp =
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

      const match = url.match(regExp)

      if (match && match[2].length === 11) {
        return match[2]
      }

      return false
    },
    prepareYTEmbed (id) {
      return `<iframe width="480" height="360" allowfullscreen src="https://www.youtube.com/embed/${id}"></iframe>`
    },
    prepareImage (src) {
      return `<img src="${src}" class="img-thumb" data-fancybox alt="${this.answer.slice(0, 50)}" />`
    }
  },
  mounted () {
    Fancybox.bind('[data-fancybox]')
  }
}
</script>

<style lang="scss" scoped>
.messageTip {
  margin: 0 0 15px 0;
  display: flex;

  .message {
    padding: 6px 10px;
    max-width: calc(100% - 15px);
    border-radius: 0 6px 6px 6px;
    color: #2C2C2E;
    background: #F2F2F7;
    position: relative;
    font-size: 13px;
    line-height: 18px;
    margin-left: 8.72px;
    white-space: pre-wrap;

    &:before {
      content: '';
      position: absolute;
      width: 8.72px;
      height: 11.12px;
      left: -8px;
      top: 0;
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      background: #F2F2F7;
    }
  }
  a {
    color: #131313;
    text-decoration: none;
    overflow-wrap: break-word;
    border-bottom: 1px solid #131313;
    &:hover {
      border-bottom: 0;
    }
  }

  &::v-deep {
    .country-flag {
      max-width: 18px;
      height: auto;
      margin: 0 0 -2px 0;
      position: relative;
      top: 2px;
    }
    iframe {
      max-width: 100%;
      height: auto;
      margin: 10px 0 0 0;
      display: block;
      border-radius: 6px;
      @media (max-width: 480px) {
        min-height: 200px;
      }
      @media (min-width: 768px) {
        width: 300px;
        min-height: 200px;
      }
      @media (min-width: 1024px) {
        width: 420px;
        min-height: 280px;
      }
    }
    .img-thumb {
      max-width: 220px;
      height: auto;
      margin: 10px 0 0 0;
      display: block;
      cursor: pointer;
      border-radius: 6px;
      @media (min-width: 1024px) {
        max-width: 420px;
      }
    }
  }

  &__own {
    &.welcome {
      margin-top: 10px;
      .message {
        min-width: 230px;
      }
    }
  }
  &__him {
    flex-direction: row-reverse;

    .message {
      border-radius: 6px 0 6px 6px;
      background: var(--main-color);
      color: #fff;
      margin-right: 8px;

      &:before {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        width: 8.72px;
        height: 11.12px;
        right: -8.72px;
        top: 0;
        display: inline-block;
        clip-path: polygon(0 0, 0 100%, 100% 0);
        background: var(--main-color);
      }
    }
  }
}

.fade-in-enter-active {
  transition: all 0.3s ease;
}

.fade-in-leave-active {
  transition: all 0.3s ease;
}

.fade-in-enter, .fade-in-leave-to {
  opacity: 0;
}
</style>
