<template>
  <div class="chatBody" id="chatBody">
    <BuyGet/>
    <div class="chatInside">
      <MessageTip
        v-for="(message, index) in messages"
        :index="index"
        :question="message.question"
        :answer="message.answer"
        :type="message.type"
        :link="message.link"
        :state="message.state"
        :key="message.id"
        @finished="scrollToEnd"
      />
      <MessageNotice v-if="wsStatus === 'limit_exceeded'" text="chat.limit_exceeded" />
      <MessageNotice v-if="wsStatus === 'validation_failed'" text="chat.validation_failed" />
      <MessageNotice v-if="wsStatus === 'language_not_allowed'" text="chat.language_not_allowed" />
    </div>
  </div>
</template>

<script>
import MessageTip from '../elements/MessageTip'
import MessageNotice from '../elements/MessageNotice'
import BuyGet from '../buy-get/Buy-Get'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Body',
  components: {
    BuyGet,
    MessageTip,
    MessageNotice
  },
  props: {
    messages: {
      type: Array
    }
  },
  data () {
    return {
      bodyHeight: 0
    }
  },
  computed: {
    ...mapGetters({
      wsStatus: 'messages/getWsStatus'
    })
  },
  watch: {
    bodyHeight (n, o) {
      if (n !== 0) {
        setTimeout(() => {
          this.scrollToEnd()
        }, 100)
      }
    }
  },
  created () {
    window.addEventListener('resize', this.calculateBodyHeight)
  },
  mounted () {
    this.scrollToEnd()
    this.calculateBodyHeight()
    this.bodyHeight = document.querySelector('.chatBody').clientHeight
  },
  updated () {
    this.scrollToEnd()
  },
  methods: {
    ...mapActions({
      calculateBodyHeight: 'chat/calculateBodyHeight',
      scrollToEnd: 'chat/scrollToEnd'
    })
  }
}
</script>

<style scoped lang="scss">
.chatBody {
  position: fixed;
  left: 0;
  right: 0;
  overflow: auto;
  .chatInside {
    padding: 0 15px 0 15px;
    overflow: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
