<template>
  <div id="chat">
    <Loader />
    <div class="chat" v-if="authState === 'loggedIn'">
      <Header @close="closeChat"/>
      <Body :messages="getMessages"/>
      <Footer @send="sendMessage"/>
    </div>
    <Authorization
      v-if="authState === 'loggedOut'"
    />
  </div>
</template>

<script>
import Loader from './elements/Loader'
import Header from './partials/Header'
import Body from './partials/Body'
import Authorization from './partials/Authorization'
import Footer from './partials/Footer'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Chat',
  components: {
    Loader,
    Header,
    Body,
    Authorization,
    Footer
  },
  computed: {
    ...mapGetters({
      getMessages: 'messages/getMessages',
      authState: 'chat/getAuthState'
    })
  },
  data () {
    return {
      tabFocus: false
    }
  },
  watch: {
    tabFocus (n) {
      if (!n) {
        return
      }
      this.fetchMe()
    }
  },
  mounted () {
    this.fetchMe()
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    ...mapActions({
      sendMessage: 'messages/sendMessage',
      closeChat: 'chat/closeChat',
      fetchMe: 'chat/fetchMe'
    }),
    handleVisibilityChange () {
      this.tabFocus = document.visibilityState === 'visible'
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/app';

.v-toast__item {
  min-height: auto;
  .v-toast__text {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    padding: 10px 25px;
  }
}

.chat {
  background: #FFFFFF;
  // box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  // width: 350px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
}

.fade-in-up-enter-active {
  transition: all 0.3s ease;
  transform: translateY(0);
}

.fade-in-up-leave-active {
  transition: all 0.3s ease;
  transform: translateY(0);
}

.fade-in-up-enter, .fade-in-up-leave-to {
  opacity: 0;
  transform: translateY(25px);
}
</style>
