import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('@/i18n/lang', true, /.*\.json$/)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const browserLanguage = () => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language

  if (userLocale.includes('-')) {
    const locale = userLocale.split('-')
    return locale[0].toLowerCase()
  }

  const supportedLang = process.env.VUE_APP_LANGS.split(',')

  if (!supportedLang.includes(userLocale)) {
    return 'en'
  }

  return userLocale
}

const i18n = new VueI18n({
  locale: (localStorage.getItem('aica_i_lang') || browserLanguage()) || 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})

export default i18n
